import React from "react"
import { Link } from 'gatsby'
import Layout from "../components/global/Layout"
import SEO from "../components/global/Seo"
import TopNav from '../components/global/TopNav'
import ArrowIcon from '../components/icons/ArrowIcon'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <TopNav />
    <div 
      className={`pt48 flexCol justifyContentCenter pageMargin1Col textCenter`} 
      style={{minHeight: 'calc(100vh - 4rem)'}}
    >
      <h1>NOT FOUND</h1>
      <p>The page you were looking for cannot be found.</p>
      <Link to={'/'} className={`h5 pt48`}>
        <i className={`inlineIcon mr8`} style={{transform: 'rotate(90deg)'}}>
          <ArrowIcon />
        </i>
        Return to Exhibition Home
      </Link>
    </div>
  </Layout>
)

export default NotFoundPage
